.contact
{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 0 2rem;
    /* justify-content:center; */
}
.contact>h1{
    color: var(--orange);
    font-weight: bold;
    margin-bottom:0rem;
}
.contact>div>img{
    width:2rem;
    fill:white;
    color:white;
    /* background:white; */
}
.contact>div{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    line-height:0.2rem;
    /* gap:2rem;     */
}
.contact>hr{
    position:absolute;
    border: 2px solid var(--orange);
    border-radius: 20%;
    width: 10.5rem;
    margin: 30rem 0;
}
.contact>div>h5{
    color:white;
    font-size:0.9rem;
}
.contact>div>span{
    /* color: var(--orange); */
    /* color:white; */
    /* font-size:1rem; */
    font-size:0.8rem;
    font-weight: bold;

}
.social-media>div>img{
    width:2rem;
}
div.social-media{
    display:flex;
    flex-direction:row;
    /* background:red; */
    gap:2rem;
    margin:1rem;
    margin:2rem 0rem;
}