.About{
    padding:0 2rem;
    display:flex;
    gap:2rem;
}
.left-a{
    flex:1 1;
}

.about-content{
    gap:1rem;
}
.about-content>div>img{
    width: 1rem;
    height: 1rem;
}
.about-content>p{
    display:flex;
    align-item:center;
    justify-content:center;
    font-size:0.94rem;
}
.right-a{
    flex:1 1;
    /* text-transform: uppercase ; */
    gap: 1rem;
    color:white;
    display: flex;
    flex-direction: column;
}
.right-a>span{
    font-weight: bold;
    color: var(--orange);
    text-transform: uppercase ;
}
.right-a .about-content{
    font-family: Georgia, serif;
    font-size:0.9rem;
    text-align: inherit;
}
.right-a .about-content span{
    font-weight: bold;
    color: var(--orange);
    text-transform: uppercase ;
}
.right-a .about-h{
    color: white;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase ;

}




@media screen and (max-width:768px)
{
    .About{
        flex-direction: column;
    }
    .left-a img{
        /* display:flex;*/
        margin-left:0.7rem; 
       width:15rem;
    }
    .About{
        padding:0 2rem;
        display:flex;
    }
    .left-a{
        flex:1 1;
    }
    
    .about-content{
        display:flex;
        flex-direction:column;
        gap:7px;
        font-family: Georgia, serif;
        line-height:1.2rem;

    }
    .about-content>div>img{
        width: 1rem;
        height: 1rem;
    }
    .right-a{
        flex:1 1;
        /* text-transform: uppercase ; */
        gap: 1rem;
        color:white;
        display: flex;
        flex-direction: column;
    }
    .right-a>span{
        font-weight: bold;
        color: var(--orange);
        text-transform: uppercase ;
    }
    .right-a .about-content{
        font-family: Georgia, serif;
        font-size:0.9rem;
        text-align: inherit;
    }
    .right-a .about-content span{
        font-weight: bold;
        color: var(--orange);
        text-transform: uppercase ;
    }
    .right-a .about-h{
        color: white;
        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase ;
    
    }
}