.header{
    display: flex;
    justify-content: space-between;
    /* margin-bottom:-5.8rem; */
}
.logo{
    height: 1.9rem;
    width: 11.5rem;
    margin-top: 8px;
    margin-left: -2rem;
}
.header-menu{
    display: flex;
    list-style: none;
    gap: 2rem;
    color: white;
}
.header-menu li{
    font-size: 15px;
}
.header-menu li:hover{
    cursor: pointer;
    color: var(--orange);
    cursor: pointer;
}
/* .header{
    padding: 3rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
} */
@media screen and (max-width:768px)
{
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background:var(--appColor);
        padding: 2rem;
    }
    /* .headerr{
        display:none;
    } */
}