.Gallary{
        display: flex;
        gap: 1rem;
        padding: 0 2rem;
        margin-top:4rem;
    }
    @media screen and (max-width:768px)
    {
        .Gallary{
            flex-direction: column;
        }
    }